import React from 'react'

const Footer = () => {
    return ( 
        <div id='footer' className='footer'>
            &copy;{new Date().getFullYear()} PixlD Inc | All rights reserved
        </div>
     );
}
 
export default Footer;